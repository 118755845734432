import React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import { FeatureRow } from '@app/components/template';
import exp2 from '@app/images/template/exp2.png';

export const Features = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'index_prepare_button' });
  const subtitle = intl.formatMessage({ id: 'index_quick_and_easy' });
  const features1 = [
    {
      icon: 'fa fa-hand-pointer-o',
      title: intl.formatMessage({ id: 'index_online_registration' }),
      description: intl.formatMessage({ id: 'index_online_registration_desc' })
    },
    {
      icon: 'fa fa-sitemap',
      title: intl.formatMessage({ id: 'index_all_in_one_place' }),
      description: intl.formatMessage({ id: 'index_all_in_one_place_desc' })
    }
  ];
  const features2 = [
    {
      icon: 'fa fa-cogs',
      title: intl.formatMessage({ id: 'index_automated_tasks' }),
      description: intl.formatMessage({ id: 'index_automated_tasks_desc' })
    },
    {
      icon: 'fa fa-wrench',
      title: intl.formatMessage({ id: 'index_tech_support' }),
      description: intl.formatMessage({ id: 'index_tech_support_desc' })
    }
  ];

  return (
    <section id="features" className="bg-secondary ptb-90">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading text-center">
              <h3>{ title }<br />{ subtitle }</h3>
              <p>
                <FormattedMessage id="index_made_by_organizers" />
                <br />
                <FormattedMessage id="index_all_you_need" />
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-md-4 col-sm-6">
            {
              features1.map((feature, i) => (
                <FeatureRow key={ i } feature={ feature } />
              ))
            }
          </div>

          <div className="col-md-4 hidden-sm hidden-xs">
            <div className="feature-image">
              <img src={ exp2 }
                   style={ {
                     maxWidth: '295px',
                     margin: '0 auto'
                   } }
                   className="pos-hcenter img-responsive" alt="" />
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            {
              features2.map((feature, i) => (
                <FeatureRow key={ i } feature={ feature } />
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
};
