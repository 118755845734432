

export const initTestimonials = (elementClass: string) => {
  // @ts-ignore
  const jq = $;
  jq(`.${ elementClass }`).owlCarousel({
    responsiveClass: true,
    margin: 20,
    dots: false,
    autoWidth: false,
    loop: true,
    nav: true,
    autoplay: true,
    navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
    autoplayTimeout: 3000,
    autoplayStopOnLast: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      800: {
        items: 2
      },
      1200: {
        items: 2
      }

    }
  });
};

export const initScreenSlider = (elementClass: string) => {
  // @ts-ignore
  const jq = $;
  jq(`.${ elementClass }`).owlCarousel({
    loop: true,
    margin: 30,
    autoplay: true,
    dots: false,
    items: 1
  });
  const u = jq(`.${ elementClass }`);
  const p = jq('#next');
  const m = jq('#prev');
  p.on('click', () => u.trigger('next.owl.carousel', [400]));
  m.on('click', () => u.trigger('prev.owl.carousel', [400]));
};

export const initBlogSlider = (elementClass: string) => {
  // @ts-ignore
  const jq = $;
  jq(`.${ elementClass }`).owlCarousel({
    items: 3,
    dots: false,
    margin: 30,
    rewind: !0,
    nav: true,
    navText: [
      '<span class="fa fa-angle-left"></span>',
      '<span class="fa fa-angle-right"></span>'
    ],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      800: {
        items: 3
      },
      1200: {
        items: 3
      }
    }
  });
}

export const initNavbar = (elementClass: string) => {
  // @ts-ignore
  const jq = $;
  jq(window).scroll(() => {
    if (jq(`.navbar`).offset().top > 50) {
      jq(`.navbar`).addClass('top-nav-collapse');
    } else {
      jq(`.navbar`).removeClass('top-nav-collapse');
    }
  });
}