import React, { Component } from 'react';
import { Link } from 'gatsby-plugin-intl';

import logo from '@app/images/logo.png';

import './Navbar.scss';

interface NavbarProps {
  active?: string;
}

interface NavbarState {
  mobileMenuVisible: boolean
}

export class Navbar extends Component<NavbarProps, NavbarState> {
  state: NavbarState = {
    mobileMenuVisible: false
  };

  mobileMenuToggle = () => {
    // console.log(this.state.mobileMenuVisible);
    this.setState(prevState => ({
      mobileMenuVisible: !prevState.mobileMenuVisible
    }));
  };

  menuItemClass(name: string): string {
    const { active } = this.props;
    if (active && active === name) {
      return 'active';
    }
    return '';
  }

  get menuClass(): string {
    return `navbar-collapse
      ${this.state.mobileMenuVisible ? '' : 'collapse'}
    `;
  }

  render() {
    return (
        <div className='navbar navbar-default app-navbar'>
          <div className="navbar-header">
            <Link to='/' className="navbar-brand">
              <img alt='karta zgloszen logo' src={logo}/>
            </Link>

            <ul className="nav navbar-nav visible-xs-block mr-10">
              <li className={this.state.mobileMenuVisible ? 'btn-active' : ''}
                  style={{ width: '50px', textAlign: 'center' }}>
                <a onClick={this.mobileMenuToggle}>
                  <i className="fas fa-align-justify"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className={this.menuClass}>
            <ul className="nav navbar-nav navbar-right">
              <li className={this.menuItemClass('index')}>
                <Link to='/'>Start</Link>
              </li>
              {/*<li className={this.menuItemClass('cennik')}>*/}
              {/*  <Link to='/cennik'>Cennik</Link>*/}
              {/*</li>*/}
              <li className={this.menuItemClass('blog')}>
                <Link to='/blog'>Blog</Link>
              </li>
              <li className={this.menuItemClass('polityka-prywatnosci')}>
                <Link to='/polityka-prywatnosci'>Polityka prywatności</Link>
              </li>
              <li className={this.menuItemClass('onas')}>
                <Link to='/onas'>O nas</Link>
              </li>
              <li className={this.menuItemClass('kontakt')}>
                <Link to='/kontakt'>Kontakt</Link>
              </li>

              <li>
                <a href="https://moja.kartazgloszen.pl/#/register?t=org"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    style={{ backgroundColor: `#00BCD4`, marginRight: '0' }}
                    className="btn btn-info text-white navbar-btn pt-10 pb-10 mt-5 mb-5 ml-10 btn-app">
                  Przejdź do aplikacji
                </a>
              </li>
            </ul>
          </div>
        </div>
    );
  }
}
