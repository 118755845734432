import React from 'react';
import top from '@app/images/top-logo.jpg';
import trendy from '@app/images/trendy-logo.jpg';
import pasjatanca from '@app/images/pasja.png';
import goldcontest from '@app/images/gold-contest.jpg';
import pceik from '@app/images/pceik.jpg';
import magiatanca from '@app/images/magiatanca.png';
import setenta from '@app/images/setenta.jpg';
import poezja from '@app/images/poezja.png';
import cooltura from '@app/images/cooltura.png';
import latinfitness from '@app/images/latinfitness.png';
import plasowadla from '@app/images/plasowadla.jpg';
import loveDance from '@app/images/love-dance.png';
import pniewy from '@app/images/pniewy.png';
import baletKrakow from '@app/images/balet-krakow.png';
import dwc from '@app/images/dwc.png';
import gwiazda from '@app/images/logo_gwiazda.png';

export class CustomerLogos extends React.PureComponent {
  componentDidMount() {
    // @ts-ignore
    $('.owl-carousel').owlCarousel({
      responsiveClass: true,
      margin: 20,
      dots: false,
      autoWidth: false,
      nav: true,
      autoplay: true,
      loop: true,
      navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
      autoplayTimeout: 3000,
      autoplayStopOnLast: false,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 3
        },
        800: {
          items: 4
        },
        1200: {
          items: 6
        }

      }
    });
  }

  render() {
    return (
      <div className="owl-carousel owl-theme testimonial-slider">
        <div className="customer-item">
          <img src={dwc} alt='dance world cup'/>
        </div>
        <div className="customer-item">
          <img src={baletKrakow} alt='krakowska akademia tanca'/>
        </div>
        <div className="customer-item">
          <img src={gwiazda} alt='grupa baletowa gwiazda'/>
        </div>
        <div className="customer-item">
          <img src={top} alt='turniej top'/>
        </div>
        <div className="customer-item">
          <img src={trendy} alt='festiwal trendy'/>
        </div>
        <div className="customer-item">
          <img src={pasjatanca} alt='cheerlederki pasja'/>
        </div>
        <div className="customer-item">
          <img src={goldcontest} alt='gold contest'/>
        </div>
        <div className="customer-item">
          <img src={pceik} alt='pceik'/>
        </div>
        <div className="customer-item">
          <img src={magiatanca} alt='magia tanca'/>
        </div>
        <div className="customer-item">
          <img src={setenta} alt='setenta'/>
        </div>
        <div className="customer-item">
          <img src={poezja} alt='poezja'/>
        </div>
        <div className="customer-item">
          <img src={cooltura} alt='cooltura'/>
        </div>
        <div className="customer-item">
          <img src={latinfitness} alt='latinfitness'/>
        </div>
        <div className="customer-item">
          <img src={plasowadla} alt='plasowadla'/>
        </div>
        <div className="customer-item">
          <img src={loveDance} alt='love dance'/>
        </div>
        <div className="customer-item">
          <img src={pniewy} alt='pniewy'/>
        </div>
      </div>
    );
  }
}
