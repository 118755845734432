import React, { PureComponent } from 'react';

import './References.scss';

export class References extends PureComponent {
  render() {
    return (
        <>
          <h1 className='text-bold mb-30'>Nasi użytkownicy</h1>
          <div className='row'>
            <div className='col-sm-6'>
              <blockquote className="mb-20">
                <img className="img-circle"
                    src='https://avatars.dicebear.com/v2/jdenticon/magda-mroz-murawska.svg'
                    alt="magda mroz murawska"/>
                Bardzo szybki sposób rejestracji (standardowe karty to jakiś koszmar), po
                turnieju w każdej chwili można sprawdzić wyniki całych kategorii i mega
                sprawna
                obsługa.
                <footer>
                  Magda Mróz-Murawska
                  <br/>
                  <cite>Instruktor Studio tańca BellArt, Wrocław</cite>
                </footer>
              </blockquote>
            </div>
            <div className='col-sm-6'>
              <blockquote className="mb-20">
                <img className="img-circle"
                    src='https://avatars.dicebear.com/v2/jdenticon/agata-judkowiak.svg'
                    alt="magda mroz murawska"/>
                Świetna aplikacja, która: usprawnia pracę organizatora, ułatwia proces
                przyjmowania zgłoszeń, daje możliwość szybkiej obsługi zgłaszających się
                zespołów.
                Karta zgłoszeń jest łatwa w obsłudze i niezawodna, a to w szczególności
                dzięki administratorowi, który wraz z możliwością zarządzania konkursem
                poprzez aplikację, oferuje swoją pomoc kiedy tylko jest potrzebna. POLECAM!!!
                <footer>
                  Agata Judkowiak
                  <br/>
                  <cite>Kierownik XVII Ogólnopolskiego Konkursu Tanecznego "Magia Tańca" im.
                    Aliny Janikowskiej, Ostrów Wielkopolski</cite>
                </footer>
              </blockquote>
            </div>
          </div>
        </>
    );
  }
}
