import React, { PureComponent } from 'react';
import './OurCustomers.scss';

import magiatanca from '@app/images/magiatanca.png';
import pasjatanca from '@app/images/pasja.png';
import latinfitness from '@app/images/latinfitness.png';
import goldcontest from '@app/images/gold-contest.jpg';
import setenta from '@app/images/setenta.jpg';
import pceik from '@app/images/pceik.jpg';
import poezja from '@app/images/poezja.png';
import cooltura from '@app/images/cooltura.png';
import plasowadla from '@app/images/plasowadla.jpg';
import trendy from '@app/images/trendy-logo.jpg';
import top from '@app/images/top-logo.jpg';
import loveDance from '@app/images/love-dance.png';
import baletKrakow from '@app/images/balet-krakow.png';
import dwc from '@app/images/dwc.png';
import gwiazda from '@app/images/logo_gwiazda.png';

export class OurCustomers extends PureComponent<{}, {}> {

  render() {
    return (
      <>
        <h1 className="text-bold mb-30">Zaufali nam</h1>
        <div className="competitions">
          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ dwc } alt="dance world cup" />
            </a>
          </div>
          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ baletKrakow } alt="krakowska akademia tanca" />
            </a>
          </div>
          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ gwiazda } alt="grupa baletowa gwiazda" />
            </a>
          </div>
          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ top } alt="turniej top" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ trendy } alt="festiwal trendy" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ pasjatanca } alt="cheerlederki pasja" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ goldcontest } alt="gold contest" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ pceik } alt="pceik" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ magiatanca } alt="magia tanca" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ setenta } alt="setenta" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ poezja } alt="poezja" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ cooltura } alt="cooltura" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ latinfitness } alt="latinfitness" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ plasowadla } alt="plasowadla" />
            </a>
          </div>

          <div className="competitions__item mb-20">
            <a href="https://moja.kartazgloszen.pl">
              <img src={ loveDance } alt="loveDance" />
            </a>
          </div>
        </div>
      </>
    );
  }
}
