import React, { useEffect } from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import waves from '@app/images/template/waves-shape.svg';
import exp1 from '@app/images/template/exp1.png';
import exp2 from '@app/images/template/exp2.png';
import exp3 from '@app/images/template/exp3.png';
import { initScreenSlider } from '@app/utils/jquery.utils';

interface MainHeroProps {
  tryForFreeClick: () => any
}

export const MainHero = ({ tryForFreeClick }: MainHeroProps) => {
  const phoneSliderClass = 'phone-slider';
  useEffect(() => {
    initScreenSlider(phoneSliderClass);
  });

  return (
    <section id="hero" className="section-lg section-hero section-circle">
      <div className="shape shape-style-1 shape-primary">
        <span className="circle-150"></span>
        <span className="circle-50"></span>
        <span className="circle-50"></span>
        <span className="circle-75"></span>
        <span className="circle-100"></span>
        <span className="circle-75"></span>
        <span className="circle-50"></span>
        <span className="circle-100"></span>
        <span className="circle-50"></span>
        <span className="circle-100"></span>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="hero-content-wrap">
              <div className="hero-content">
                <h1>
                  <FormattedMessage id="index_title_first" />
                  <br />
                  <FormattedMessage id="index_title_second" />
                </h1>
                <p>
                  <FormattedMessage id="index_subtitle" />
                </p>
                <div className="slider-action-btn">
                  <a onClick={ () => tryForFreeClick() }
                          style={ { textTransform: 'none' } }
                          className="btn softo-solid-btn mb-10">
                    <FormattedMessage id="index_try_free" />
                  </a>
                  <Link to='/features' className="btn softo-solid-btn mb-10" style={ {
                    border: '1px solid #fff',
                    color: '#fff',
                    background: 'transparent',
                    textTransform: 'none'
                  } }>
                    <FormattedMessage id="index_learn_more" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mobile-slider-area">
              <div className="phone-container">
                <div className={`${phoneSliderClass} owl-carousel owl-theme`}>
                  <div className="item">
                    <img src={ exp1 } alt="Screen 1" className="img-responsive" />
                  </div>
                  <div className="item">
                    <img src={ exp2 } alt="Screen 2" className="img-responsive" />
                  </div>
                  <div className="item">
                    <img src={ exp3 } alt="Screen 3" className="img-responsive" />
                  </div>
                </div>
              </div>

              <div className="slider-indicator">
                <ul>
                  <li id="prev">
                    <i className="fa fa-angle-left"></i>
                  </li>
                  <li id="next">
                    <i className="fa fa-angle-right"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-shape">
        <img src={ waves } alt="shape image" />
      </div>
    </section>
  );
};