import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface TryForFreeProps {
  title: string;
  description: string;
  buttonClick: () => any
}

export const TryForFree = ({ title, description, buttonClick }: TryForFreeProps) => {

  return (
    <section className="ptb-90">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading text-center">
              <h3>{ title }</h3>
              <p>{ description }</p>

            </div>
            <p className='text-center'>
              <a onClick={() => buttonClick()} className="btn softo-solid-btn">
                <FormattedMessage id="index_try_for_free" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
