import React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

export const Contact = () => {
  const intl = useIntl();
  return (
    <section id="contact" className="contact-us ptb-90">
      <div className="contact-us-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="section-heading">
                <h3>
                  <FormattedMessage id="contact_title" />
                </h3>
              </div>
              <div className="footer-address">
                <h6>
                  <FormattedMessage id="contact_company_info" />
                </h6>
                <p>Setenta Paweł Radomski</p>
                <ul className='mb-15'>
                  <li><FormattedMessage id="contact_tax_id" /></li>
                  <li>ul. Przylesie 76, 55-095 Domaszczyn</li>
                  <li>
                    <FormattedMessage id="contact_country" />
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="fa fa-phone"></i>
                    <FormattedMessage id="contact_mobile" />
                  </li>
                  <li>
                    <i className="fa fa-envelope-o"></i>
                    <span>
                      Email:
                      &nbsp;
                      <a href="mailto:kontakt@kartazgloszen.pl">kontakt@kartazgloszen.pl</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form action="https://formspree.io/p4welo@gmail.com" method="POST"
                    id="contactForm1" className="contact-us-form"
                    noValidate={ true }>
                <h6>
                  <FormattedMessage id="contact_quick_message" />
                </h6>
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input type="text" className="form-control" name="name"
                             placeholder={ intl.formatMessage({ id: 'contact_first_last_name' }) }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email"
                             placeholder="Email" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input type="text"
                             name="phone"
                             className="form-control"
                             id="phone"
                             placeholder={ intl.formatMessage({ id: 'contact_phone' }) }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input type="text" name="company" size={ 40 }
                             className="form-control"
                             id="company"
                             placeholder={ intl.formatMessage({ id: 'contact_company' }) }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                              <textarea name="message"
                                        id="message"
                                        className="form-control"
                                        rows={ 7 }
                                        cols={ 25 }
                                        placeholder={ intl.formatMessage({ id: 'contact_message' }) }
                              ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-20">
                    <button type="submit" className="btn softo-solid-btn pull-right"
                            id="btnContactUs">
                      <FormattedMessage id="contact_submit" defaultMessage=" cos nie dziala" />
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};