import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import logoBlack from '@app/images/logo.png';

export const Footer = () => {
  return (
    <footer className="footer-section bg-secondary ptb-60">
      <div className="footer-wrap">
        <div className="container">
          <div className="row pb-60">
            <div className="col-md-3 pb-15">
              <p>
                <strong>
                  <FormattedMessage id="footer_fast_links" />
                </strong>
              </p>
              <p>
                <Link to='/'>
                  <FormattedMessage id="footer_home" />
                </Link>
              </p>
              <p>
                <Link to='/features'>
                  <FormattedMessage id="footer_features" />
                </Link>
              </p>
              {/*<p>*/}
              {/*  <Link to='/prices'>*/}
              {/*    <FormattedMessage id="footer_prices" />*/}
              {/*  </Link>*/}
              {/*</p>*/}
              <p>
                <Link to='/blog'>
                  <FormattedMessage id="footer_blog" />
                </Link>
              </p>
              <p>
                <Link to='/contact'>
                  <FormattedMessage id="footer_contact" />
                </Link>
              </p>
            </div>
            <div className="col-md-3 pb-15">
              <p>
                <strong>
                  <FormattedMessage id="footer_customer_service" />
                </strong>
              </p>
              <p>
                <Link to='/privacy'>
                  <FormattedMessage id="footer_privacy" />
                </Link>
              </p>
              <p>
                <Link to='/regulations'><FormattedMessage id="footer_regulations" /></Link>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>
                  <FormattedMessage id="footer_company_info" />
                </strong>
              </p>
              <p>Setenta Paweł Radomski</p>
              <ul className='mb-15'>
                <li><FormattedMessage id="contact_tax_id" /></li>
                <li>ul. Przylesie 76, 55-095 Domaszczyn</li>
                <li>
                  <FormattedMessage id="contact_country" />
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="">
              <div className="footer-single-col text-center">
                <img src={ logoBlack } alt="footer logo" style={ { height: '30px' } } />
                <div className="footer-social-list">
                  <ul className="list-inline">
                    <li>
                      <a href="https://www.facebook.com/kartazgloszen" target='_blank'>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>
                    &copy;
                    <Link to="/">
                      <FormattedMessage id="footer_brand" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
