export interface AccountData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repassword: string;
  agreement: boolean;

  name: string;
  phone: string;
  // city: string;
  language: 'pl' | 'en';
  // country: string;
  type: string;
}

// firstName, lastName, name, phone, city, email, password, type, language, country

export enum CreateState {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR
}