import React, { useEffect } from 'react';
import { useIntl, IntlConfig } from 'react-intl';
import logo from '@app/images/logo-karta-white.png';
import flagPl from '@app/images/pl.png';
import flagEn from '@app/images/en.png';
import { FormattedMessage, Link, changeLocale } from 'gatsby-plugin-intl';
import { initNavbar } from '@app/utils/jquery.utils';

import './Header.scss';

export const Header = () => {
  const ELEMENT_CLASS = 'navbar'
  const intlConfig: IntlConfig = useIntl() as IntlConfig;
  const locale = intlConfig.locale;
  useEffect(() => initNavbar(ELEMENT_CLASS));

  return (
    <header className="header Header">
      <div className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="row">
            <div className="navbar-header page-scroll">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                      data-target="#myNavbar">
                  <span className="sr-only">
                    <FormattedMessage id="header_toggle" />
                  </span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link className="navbar-brand page-scroll" to="/">
                <img src={ logo } alt="logo" style={ { height: '30px' } } />
              </Link>
            </div>

            <div className="navbar-collapse collapse" id="myNavbar" style={{maxHeight: '600px'}}>
              {/*<ul className="nav navbar-nav">*/}
              {/*  <li className="dropdown">*/}
              {/*    <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"*/}
              {/*       aria-expanded="false">*/}
              {/*      <i className="fa fa-globe" style={{marginRight: '0.5rem'}}></i>*/}
              {/*      <FormattedMessage id="language" />*/}
              {/*      <span className="caret" style={{marginLeft: '0.5rem'}}></span>*/}
              {/*    </a>*/}
              {/*    <ul className="dropdown-menu">*/}
              {/*      <li><a onClick={() => changeLocale('pl')}>Polski</a></li>*/}
              {/*      <li><a onClick={() => changeLocale('en')}>English</a></li>*/}
              {/*    </ul>*/}
              {/*  </li>*/}
              {/*</ul>*/}

              <ul className="nav navbar-nav navbar-right">
                <li>
                  <Link to='/' className="page-scroll">
                    <FormattedMessage id="footer_home" />
                  </Link>
                </li>
                <li>
                  <Link to='/features' className="page-scroll">
                    <FormattedMessage id="footer_features" />
                  </Link>
                </li>
                <li>
                  <Link to='/blog' className="page-scroll">
                    <FormattedMessage id="footer_blog" />
                  </Link>
                </li>
                <li>
                  <Link to='/contact' className="page-scroll">
                    <FormattedMessage id="footer_contact" />
                  </Link>
                </li>
                <li>
                  <a href="https://moja.kartazgloszen.pl/#/login" className="page-scroll">
                    <FormattedMessage id="footer_login" />
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                     style={{display: 'flex', alignItems: 'center'}}
                     aria-expanded="false">
                    <img src={ locale === 'pl' ? flagPl : flagEn }
                         style={{height: '1.25rem'}}
                         alt="language pl" />
                    <span className="caret" style={{marginLeft: '0.5rem'}}></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a onClick={() => changeLocale('pl')} style={{display: 'flex', alignItems: 'center', padding: '20px'}}>
                        <img src={flagPl}
                             style={{height: '1.25rem', marginRight: '1rem'}} alt="pl" />
                        Polski
                      </a>
                    </li>
                    <li>
                      <a onClick={() => changeLocale('en')} style={{display: 'flex', alignItems: 'center', padding: '20px'}}>
                        <img src={flagEn}
                             style={{height: '1.25rem', marginRight: '1rem'}} alt="pl" />
                        English
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
