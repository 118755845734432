import React from 'react';
import './PriceList.scss';
// import { Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

import kalendarz from '@app/images/kalendarz.png';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

export const PriceList = () => {

  // const popoverLeft = (text: string) => (
  //   <Popover id="popover-positioned-left">{text}</Popover>
  // );

  return (
    <>
      <div className="ptb-90">

        <p className="">
          <span>Podane ceny nie uwzględniają podatku VAT</span>
        </p>

        <div className="table-responsive">
          <table className="table table-hover  text-center">
            <thead>
            <tr>
              <th>
                {/*<FormattedMessage id="prices_choose_package" />*/}
              </th>
              {/*<th className="text-center">*/}
              {/*  <h6 className="text-semibold mt-5 mb-5">*/}
              {/*    <FormattedMessage id="prices_basic" />*/}
              {/*  </h6>*/}
              {/*  <h4 className="text-semibold no-margin">*/}

              {/*    <FormattedMessage id="prices_basic_price" />*/}
              {/*    &nbsp;*/}
              {/*    <span className="text-size-base text-muted text-regular">*/}
              {/*      /&nbsp;*/}
              {/*      <FormattedMessage id="prices_competition" />*/}
              {/*    </span>*/}
              {/*  </h4>*/}
              {/*</th>*/}
              <th className="text-center">
                {/*<h6 className="text-semibold mt-5 mb-5">*/}
                {/*  <FormattedMessage id="prices_extended" />*/}
                {/*</h6>*/}
                <h4 className="text-semibold no-margin">
                  <FormattedMessage id="prices_extended_price" />
                  &nbsp;
                  <span className="text-size-base text-muted text-regular">
                    /&nbsp;
                    <FormattedMessage id="prices_competition" />
                  </span>
                </h4>
              </th>
              {/*<th className="text-center">*/}
              {/*  <h6 className="text-semibold mt-5 mb-5">Pełny</h6>*/}
              {/*  <h4 className="text-semibold no-margin">*/}
              {/*    <span className="text-size-base text-muted text-regular">wkrótce!</span>*/}
              {/*  </h4>*/}
              {/*</th>*/}
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="text-left">
                <FormattedMessage id="prices_online_registration" />

                {/*<OverlayTrigger trigger="hover" placement="right"*/}
                {/*                overlay={popoverLeft('Wygodny formularz zapisów')}>*/}
                {/*  <Button bsStyle="link">*/}
                {/*    <i className="fa fa-question-circle-o"/>*/}
                {/*  </Button>*/}
                {/*</OverlayTrigger>*/}
              </td>
              {/*<td>*/}
              {/*  <p className='text-muted no-margin-bottom'>*/}
              {/*    <FormattedMessage id="prices_online_registration_no_limit" />*/}
              {/*  </p>*/}
              {/*</td>*/}
              <td>
                <p className='text-muted no-margin-bottom'>
                  <FormattedMessage id="prices_online_registration_no_limit" />
                </p>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <FormattedMessage id="prices_languages" />

                {/*<OverlayTrigger trigger="hover" placement="right"*/}
                {/*                overlay={popoverLeft('Wygodny formularz zapisów')}>*/}
                {/*  <Button bsStyle="link">*/}
                {/*    <i className="fa fa-question-circle"/>*/}
                {/*  </Button>*/}
                {/*</OverlayTrigger>*/}
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <FormattedMessage id="prices_payment" />
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <p className='no-margin-bottom'>
                  <FormattedMessage id="prices_starting_lists" />
                </p>
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <p className='no-margin-bottom'>
                  <FormattedMessage id="prices_starting_numbers" />
                </p>
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <p className='no-margin-bottom'>
                  <FormattedMessage id="prices_judge_cards" />
                </p>
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <p className='no-margin-bottom'>
                  <FormattedMessage id="prices_email_notifications" />
                </p>
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <FormattedMessage id="prices_sms_notifications" />
              </td>
              {/*<td>*/}
              {/*           <span className='label label-danger'>*/}
              {/*              <i className="fa fa-times"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td className="text-left">Wyniki</td>*/}
            {/*  <td>*/}
            {/*            <span className='label label-danger'>*/}
            {/*                <i className="fa fa-times"></i>*/}
            {/*            </span>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*            <span className='label label-success'>*/}
            {/*                <i className="fa fa-check"></i>*/}
            {/*            </span>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*            <span className='label label-success'>*/}
            {/*                <i className="fa fa-check"></i>*/}
            {/*            </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            <tr>
              <td className="text-left">
                <FormattedMessage id="prices_music_management" />
              </td>
              {/*<td>*/}
              {/*          <span className='label label-danger'>*/}
              {/*              <i className="fa fa-times"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <FormattedMessage id="prices_video_management" />
              </td>
              {/*<td>*/}
              {/*          <span className='label label-danger'>*/}
              {/*              <i className="fa fa-times"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left no-padding-top no-padding-bottom"
                  style={ { display: 'flex', alignItems: 'center' } }>
                <FormattedMessage id="prices_highlighted" />
                <a href='https://www.tanecznykalendarz.pl/' target='_blank'>
                  <img src={ kalendarz } alt='taneczny kalendarz' style={ { height: '60px', marginLeft: '1rem' } } />
                </a>
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left text-semibold">
                <FormattedMessage id="prices_support" />
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            <tr>
              <td className="text-left text-semibold">
                <FormattedMessage id="prices_money_back" />
              </td>
              {/*<td>*/}
              {/*          <span className='label label-success'>*/}
              {/*              <i className="fa fa-check"></i>*/}
              {/*          </span>*/}
              {/*</td>*/}
              <td>
                        <span className='label label-success'>
                            <i className="fa fa-check"></i>
                        </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p>
          <small>
            <FormattedMessage id="prices_details_in_regulations" />
            <Link to='/regulations'>
              <FormattedMessage id="prices_regulations_link" />
            </Link>
          </small>
        </p>

      </div>
    </>
  );
};
