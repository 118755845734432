import React, { PureComponent } from 'react';

interface FeatureRowProps {
  feature: {
    icon: string;
    title: string;
    description: string;
  }
}

export class FeatureRow extends PureComponent<FeatureRowProps> {

  render() {
    return (
      <div className="single-feature mb-5">
        <div className="feature-icon">
          <div className="icon icon-shape bg-color white-text">
            <i className={ this.props.feature.icon }></i>
          </div>
        </div>
        <div className="feature-content">
          <h5>{ this.props.feature.title }</h5>
          <p className="mb-0">{ this.props.feature.description }</p>
        </div>
      </div>
    );
  }
}