import React, { PureComponent } from 'react';

import './Hero.scss';
import pc from '@app/images/pc.png';
import { Link } from 'gatsby';

export class Hero extends PureComponent<any, any> {

  render() {
    return (
          <section className="hero">
            <div className='hero-inner'>
              <div className="pl-10 pr-10" style={{ marginTop: '100px' }}>
                <h1 className="no-margin">Organizatorze!</h1>
                <h1 className="no-margin" style={{fontSize: '3em'}}>My wiemy, co spędza Ci sen z powiek</h1>
                <h2 className="mb-20"></h2>
                <div className='text-center mt-50 main-buttons'>
                  <Link to='/kontakt'
                      className="btn btn-info btn-xlg">
                    <i className="far fa-envelope position-left"></i>
                    <span>wypróbuj&nbsp;</span>
                    ZA DARMO
                  </Link>
                  {/*<button onClick={this.props.scrollToDetails}*/}
                  {/*    className="btn btn-flat border-white text-white btn-xlg">*/}
                  {/*  dowiedz się więcej*/}
                  {/*</button>*/}
                </div>
              </div>
            </div>
            <div className='text-center main-image'>
              <img src={pc} alt='screenshot'/>
            </div>
          </section>
    );
  }
}
