import React, {PureComponent} from 'react';

import './ContactForm.scss';

export class ContactForm extends PureComponent{
  render() {
    return (
        <>
          <h2 className="text-bold mb-30">Kontakt</h2>
          <h4 className="">
            <strong>Setenta Paweł Radomski</strong>
            <p className='no-margin-bottom'>NIP: 6472489879</p>
            <p className='no-margin-bottom'>Przylesie 76</p>
            <p>55-095 Domaszczyn</p>
          </h4>

          <section id='contact-form' style={{ minHeight: '150px', paddingTop: '50px' }}>
            <div className='row'>
              <div className='col-sm-6'>
                <h2 className='no-margin-bottom'>Wypełnij formularz</h2>
                <h4>
                  lub zadzwoń po więcej informacji:
                  <a href='tel:793432042' className='position-right btn btn-default'>793 432 042</a>
                </h4>
                <div className='panel panel-flat'>
                  <div className='panel-body'>
                    <form action="https://formspree.io/p4welo@gmail.com" method="POST">
                      <div className="form-group">
                        <input type="text"
                            className="form-control"
                            placeholder="Nazwa turnieju"
                            name='turniej'/>
                      </div>
                      <div className="form-group">
                        <input type="text"
                            className="form-control"
                            placeholder="Imię i nazwisko"
                            name='organizator'/>
                      </div>
                      <div className="form-group">
                        <input type="text"
                            className="form-control"
                            placeholder="Telefon"
                            name='telefon'/>
                      </div>
                      <div className="form-group">
                        <input type="email"
                            className="form-control"
                            placeholder="Email"
                            name='email'/>
                      </div>
                      <div className="form-group">
                            <textarea className="form-control"
                                placeholder="Komentarz"
                                rows={5}
                                name='komentarz'/>
                      </div>
                      <button className='btn btn-info' type='submit'>Wyślij</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>


        </>
    )
  }
}
