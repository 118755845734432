import React, { useEffect } from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';
import { initBlogSlider } from '@app/utils/jquery.utils';

interface NewsProps {
  postList: {
    path: string;
    title: string;
    date: string;
    image: any;
  }[];
}
export const News = (props: NewsProps) => {

  useEffect(() => {
    initBlogSlider('owl-carousel');
  });

  return (
    <section id="news" className="ptb-90 bg-secondary">
      <div className="blog-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading text-center">
                <h3>
                  <FormattedMessage id="index_blog_for_organizers" />
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            {
              props.postList.map((post, i) => (
                <div className="owl-carousel" key={ i }>
                  <div className="single-blog-article">
                    <div className="blog-post-img-thumb">
                      <Link to={ post.path }>
                        <Img className='thumb-image'
                             fluid={ post.image }
                             alt={ post.title } />
                      </Link>
                    </div>
                    <div className="blog-post-content-area">
                      <h3>
                        <Link to={ post.path }>{ post.title }</Link>
                      </h3>
                      <div className="blog-footer-area">
                        <Link to={ post.path } className="readmore-btn">
                          <FormattedMessage id="index_blog_read_more" />
                          <i className="fa fa-angle-right"/>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
}
