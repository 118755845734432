import React, { useEffect } from 'react';
import { initTestimonials } from '@app/utils/jquery.utils';
import { FormattedMessage } from "gatsby-plugin-intl";

export const Testimonials = () => {
  const ELEMENT_CLASS = 'testimonial-slider'
  useEffect(() => {
    initTestimonials(ELEMENT_CLASS)
  });

  return (
    <section className="testimonial-section bg-secondary ptb-90">
      <div className="testimonial-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading text-center">
                <h3>
                  <FormattedMessage id="index_check_what_users_say" />
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="owl-carousel owl-theme testimonial-slider">
                <div className="item">
                  <div className="single-testimonial-item">
                    <span className="blockquote-icon">
                      <i className="fa fa-quote-left"/>
                    </span>
                    <div className="testimonial-content">
                      <p>
                        <FormattedMessage id="testimonials_description1" />
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <img src='https://avatars.dicebear.com/v2/jdenticon/roman-wojdowski.svg' alt="" />
                      <div className="testimonial-author-info">
                        <h6>
                          <FormattedMessage id="testimonials_author1" />
                        </h6>
                        <span>
                          <FormattedMessage id="testimonials_author_desc1" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="single-testimonial-item">
                    <span className="blockquote-icon"><i className="fa fa-quote-left"></i></span>
                    <div className="testimonial-content">
                      <p>
                        <FormattedMessage id="testimonials_description2" />
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <img src='https://avatars.dicebear.com/v2/jdenticon/magda-mroz-murawska.svg' alt="" />
                      <div className="testimonial-author-info">
                        <h6>
                          <FormattedMessage id="testimonials_author2" />
                        </h6>
                        <span>
                          <FormattedMessage id="testimonials_author_desc2" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="single-testimonial-item">
                    <span className="blockquote-icon"><i className="fa fa-quote-left"></i></span>
                    <div className="testimonial-content">
                      <p>
                        <FormattedMessage id="testimonials_description3" />
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <img src='https://avatars.dicebear.com/v2/jdenticon/agata-judkowiak.svg' alt="" />
                      <div className="testimonial-author-info">
                        <h6>
                          <FormattedMessage id="testimonials_author3" />
                        </h6>
                        <span>
                          <FormattedMessage id="testimonials_author_desc3" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// export class Testimonials extends PureComponent {
//   componentDidMount() {
//     // @ts-ignore
//     $('.testimonial-slider').owlCarousel({
//       responsiveClass: true,
//       margin: 20,
//       dots: false,
//       autoWidth: false,
//       loop: true,
//       nav: true,
//       autoplay: true,
//       navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
//       autoplayTimeout: 3000,
//       autoplayStopOnLast: false,
//       responsive: {
//         0: {
//           items: 1
//         },
//         600: {
//           items: 2
//         },
//         800: {
//           items: 2
//         },
//         1200: {
//           items: 2
//         }
//
//       }
//     });
//   }
//
//   render() {
//     return (
//       <section className="testimonial-section bg-secondary ptb-90">
//         <div className="testimonial-wrap">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="section-heading text-center">
//                   <h3>Zobacz, co mówią nasi Użytkownicy</h3>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="owl-carousel owl-theme testimonial-slider">
//                   <div className="item">
//                     <div className="single-testimonial-item">
//                       <span className="blockquote-icon"><i className="fa fa-quote-left"></i></span>
//                       <div className="testimonial-content">
//                         <p>
//                           System znakomicie sprawdza się pod każdym kątem, począwszy od rejestracji tancerzy do płatności akredytacji.
//                           Wprowadzane zmiany są na bieżąco uaktualniane i powiadomieniem smsowym informują użytkownika o zmianach.
//                         </p>
//                         <p>System bardzo przejrzysty i łatwy w obsłudze, oszczędza dużo czasu organizatorom zawodów.</p>
//                         <p>Polecam jak najbardziej ten system organizatorom.</p>
//                         <p>5 gwiazdek ;)</p>
//                       </div>
//                       <div className="testimonial-author">
//                         <img src='https://avatars.dicebear.com/v2/jdenticon/roman-wojdowski.svg' alt="" />
//                         <div className="testimonial-author-info">
//                           <h6>Roman Wojdowski</h6>
//                           <span>Organizator - Szkoła tańca Poezja, Kościan</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//
//                   <div className="item">
//                     <div className="single-testimonial-item">
//                       <span className="blockquote-icon"><i className="fa fa-quote-left"></i></span>
//                       <div className="testimonial-content">
//                         <p>Bardzo szybki sposób rejestracji (standardowe karty to jakiś koszmar), po turnieju w każdej
//                           chwili można sprawdzić wyniki całych kategorii i mega sprawna obsługa.</p>
//                       </div>
//                       <div className="testimonial-author">
//                         <img src='https://avatars.dicebear.com/v2/jdenticon/magda-mroz-murawska.svg' alt="" />
//                         <div className="testimonial-author-info">
//                           <h6>Magda Mróz</h6>
//                           <span>Instruktor - Studio tańca BellArt, Wrocław</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//
//                   <div className="item">
//                     <div className="single-testimonial-item">
//                       <span className="blockquote-icon"><i className="fa fa-quote-left"></i></span>
//                       <div className="testimonial-content">
//                         <p>Świetna aplikacja, która: usprawnia pracę organizatora, ułatwia proces przyjmowania zgłoszeń,
//                           daje możliwość szybkiej obsługi zgłaszających się zespołów. Karta zgłoszeń jest łatwa w
//                           obsłudze i niezawodna, a to w szczególności dzięki administratorowi, który wraz z możliwością
//                           zarządzania konkursem poprzez aplikację, oferuje swoją pomoc kiedy tylko jest potrzebna.
//                           POLECAM!!!</p>
//                       </div>
//                       <div className="testimonial-author">
//                         <img src='https://avatars.dicebear.com/v2/jdenticon/agata-judkowiak.svg' alt="" />
//                         <div className="testimonial-author-info">
//                           <h6>Agata Judkowiak</h6>
//                           <span>Kierownik XVII Ogólnopolskiego Konkursu Tanecznego "Magia Tańca" im. Aliny Janikowskiej, Ostrów Wielkopolski</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     );
//   }
// }