import React from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl";

interface SeoProps {
  description?: string;
  lang?: string;
  meta?: any;
  keywords?: string[];
  title?: string;
  image?: string;
  url?: string;
}

export const Seo = ({ description, lang, meta, keywords = [], title, image, url }: SeoProps) => {
  const intl = useIntl();
  return (
      <StaticQuery
          query={detailsQuery}
          render={data => {
            const metaDescription =
                description || data.site.siteMetadata.description;
            const language = intl.locale === 'pl' ? `pl_PL` : 'en_US';
            return (
                <Helmet
                    htmlAttributes={{
                      lang: intl.locale,
                    }}
                    title={title}
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    meta={[
                      {
                        name: `description`,
                        content: metaDescription,
                      },
                      {
                        property: `og:title`,
                        content: title,
                      },
                      {
                        property: `og:description`,
                        content: metaDescription,
                      },
                      {
                        property: `og:type`,
                        content: `website`,
                      },
                      {
                        property: `og:image`,
                        content: image,
                      },
                      {
                        property: `og:url`,
                        content: `https://kartazgloszen.pl${url}`,
                      },
                      {
                        name: `og:locale`,
                        content: language,
                      },
                      {
                        name: `twitter:card`,
                        content: `summary`,
                      },
                      {
                        name: `twitter:creator`,
                        content: data.site.siteMetadata.author,
                      },
                      {
                        name: `twitter:title`,
                        content: title,
                      },
                      {
                        name: `twitter:description`,
                        content: metaDescription,
                      },
                    ]
                        .concat(
                            keywords.length > 0
                                ? {
                                  name: `keywords`,
                                  content: keywords.join(`, `),
                                }
                                : []
                        )
                        .concat(meta)}
                />
            );
          }}
      />
  );
};

Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  keywords: [],
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`;
