import React, { PureComponent } from 'react';

import time from '@app/images/time.jpg';
import managerPhone from '@app/images/manager-phone.jpg';
import managerWoman from '@app/images/manager-woman.jpg';
import payment from '@app/images/payment.png';

import './Features.scss';

export class Features extends PureComponent<{}, {}> {

  render() {
    return (
        <>
          <div className='row feature-row' id='learnMore'>
            <div className='col-sm-6'>
              <img src={ managerWoman } alt='manaqger'/>
            </div>

            <div className='col-sm-6 pr-20 pl-20'>
              <h2 className='text-bold mb-30'>Maksymalna kontrola nad
                turniejem</h2>
              <ul className="media-list">
                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-tablet-alt text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Wygodny dostęp z poziomu telefonu, tabletu i komputera
                      </label>
                    </h6>
                    <p>w każdym miejscu i o każdej porze</p>
                  </div>
                </li>

                <li className="media">
                  <div className="media-left">
                    <i className="far fa-list-alt text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Przejrzyste zestawienie zgłoszonych szkół, tancerzy
                      </label>
                    </h6>
                    <p>z podziałem na dni i kategorie</p>
                  </div>
                </li>

                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-music text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Obsługa muzyki
                      </label>
                    </h6>
                    <p>pobieraj pliki audio w kolejności występów na turnieju</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='row feature-row'>
            <div className='col-sm-6 switched'>
              <img src={payment} alt='listy startowe'/>
            </div>

            <div className='col-sm-6 pl-20 pr-20 '>
              <h2 className='text-bold mb-30'>Obsługa płatności</h2>
              <ul className="media-list">
                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-search-dollar text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Nadzór nad płatnościami
                      </label>
                    </h6>
                    <p>przejrzyste podsumowanie opłaty startowej dla każdej szkoły</p>
                  </div>
                </li>

                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-file-invoice-dollar text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Integracja z popularnymi systemami księgowymi
                      </label>
                    </h6>
                    <p>jednym kliknięciem wystawiaj i wysyłaj faktury swoim klientom</p>
                  </div>
                </li>

                <li className="media">
                  <div className="media-left">
                    <i className="far fa-credit-card text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Integracja z systemem płatności online
                      </label>
                    </h6>
                    <p>automatyczne księgowanie płatności - opcja</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='row feature-row'>
            <div className='col-sm-6'>
              <img src={managerPhone} alt='listy startowe'/>
            </div>

            <div className='col-sm-6 pl-20 pr-20 '>
              <h2 className='text-bold mb-30'>Trzymaj rękę na pulsie</h2>
              <ul className="media-list">
                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-sms text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Powiadomienia SMS/email na żywo
                      </label>
                    </h6>
                    <p>otrzymuj powiadomienia o nowych zgłoszeniach</p>
                  </div>
                </li>

                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-chart-line text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Zestawienia i raporty przydatne podczas przygotowań
                      </label>
                    </h6>
                    <p>zestawienia zapisanych szkół, przewidywane wpływy, zapotrzebowanie na
                      szatnie itp.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='row feature-row'>
            <div className='col-sm-6 switched'>
              <img src={time} alt='czas wolny'/>
            </div>

            <div className='col-sm-6 pr-20 pl-20'>
              <h2 className='text-bold mb-30'>Oszczędność czasu</h2>

              <ul className="media-list">
                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-sync-alt text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        Automatyczne generowanie list i dokumentów
                      </label>
                    </h6>
                    <p>numerów startowych, list startowych, arkuszy jury, dyplomów, teczki
                      uczestnika, itp.</p>
                  </div>
                </li>
                <li className="media">
                  <div className="media-left">
                    <i className="fas fa-gavel text-blue position-left"></i>
                  </div>
                  <div className="media-body">
                    <h6 className="media-heading">
                      <label className="no-margin text-bold">
                        RODO
                      </label>
                    </h6>
                    <p>gwarantujemy bezpieczeństwo i prywatność Twoich klientów zgodnie z
                      obowiązującymi przepisami</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
    );
  }

}
