import React, { useState } from 'react';
import { AccountData } from '@app/model';

export function useFormFields<T>(initialValues: T) {
  const [formFields, setFormFields] = useState<T>(initialValues);
  const createChangeHandler = (key: keyof T) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFormFields((prev: T) => ({ ...prev, [key]: value }));
  };
  return { formFields, createChangeHandler };
}

export const validateAccountData = ({ firstName, lastName, name, phone, email, password, repassword, agreement }: AccountData, setErrors: any) => {
  setErrors({});
  let valid = true;

  if (firstName?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, firstName: 'required' }));
    valid = false;
  }

  if (lastName?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, lastName: 'required' }));
    valid = false;
  }

  if (name?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, name: 'required' }));
    valid = false;
  }

  if (phone?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, phone: 'required' }));
    valid = false;
  }

  const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, email: 'required' }));
    valid = false;
  } else if (!EMAIL_REGEX.test(email.toLowerCase())) {
    setErrors((prev: AccountData) => ({ ...prev, email: 'email' }));
    valid = false;
  }

  if (password?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, password: 'required' }));
    valid = false;
  }

  if (repassword?.length < 1) {
    setErrors((prev: AccountData) => ({ ...prev, repassword: 'required' }));
    valid = false;
  } else if (repassword !== password) {
    setErrors((prev: AccountData) => ({ ...prev, repassword: 'match' }));
    valid = false;
  }

  if (!agreement) {
    setErrors((prev: AccountData) => ({ ...prev, agreement: 'required' }));
    valid = false;
  }
  return valid;
};