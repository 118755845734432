import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const AlmostHero = () => {
  return (
    <section id="almost-hero" className="ptb-90">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading text-center" style={ { marginBottom: '0' } }>
              <h3>
                <FormattedMessage id="index_sounds_familiar" />
              </h3>
              <p>
                <FormattedMessage id="index_competition_lots_of_work" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};