import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { AccountData, CreateState } from '@app/model';
import { useFormFields, validateAccountData } from '@app/utils/form.utils';

interface CreateAccountProps {
  state: CreateState;
  submit: any;
  cancel: any;
}

export const CreateAccount = ({ state, submit, cancel }: CreateAccountProps) => {
  const intl = useIntl();
  const [errors, setErrors] = useState({});

  const { formFields, createChangeHandler } = useFormFields<AccountData>({
    firstName: '',
    lastName: '',
    name: '',
    phone: '',
    email: '',
    password: '',
    repassword: '',
    language: intl.locale === 'pl' ? 'pl' : 'en',
    agreement: false,
    type: 'COMPETITION'
  });

  const dismiss = () => {
    cancel();
  };

  const handleSubmit = () => {
    if (validateAccountData(formFields, setErrors)) {
      const { firstName, lastName, name, email, password, repassword, language, agreement, type, phone } = formFields;
      submit({ firstName, lastName, name, email, password, repassword, language, agreement, type, phone });
    }
  };

  const fieldRequired = <small className="text-danger" style={ { position: 'absolute', fontSize: '0.75rem' } }>Field
    required</small>;
  const passwordDoesNotMatch = <small className="text-danger" style={ { position: 'absolute', fontSize: '0.75rem' } }>Password
    does not match</small>;
  const wrongEmail = <small className="text-danger" style={ { position: 'absolute', fontSize: '0.75rem' } }>Wrong
    email</small>;

  const errorField = (field: string) => {
    // @ts-ignore
    const type = errors[field];
    if (type === 'required') {
      return fieldRequired;
    } else if (type === 'match') {
      return passwordDoesNotMatch;
    } else if (type === 'email') {
      return wrongEmail;
    }
    return <span />;
  };

  const bodyInProgress = (
    <>
      <Modal.Body style={ {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        background: 'white',
        opacity: '0.9',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      } }>
        <h5 className="text-center">
          <i className="fa fa-cog fa-spin fa-3x fa-fw" />
        </h5>
        <p className="text-center">
          <FormattedMessage id="create_account_in_progress" />
        </p>
      </Modal.Body>
    </>
  );

  const bodySuccess = (
    <>
      <Modal.Body style={ {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        background: 'white',
        opacity: '0.9',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      } }>
        <h5 className="text-center text-success">
          <i className="fa fa-check-circle fa-3x fa-fw" />
        </h5>
        <p className="text-center">
          <FormattedMessage id="create_account_success" />
        </p>
      </Modal.Body>
    </>
  );

  const userExistsAlert = (
    <div className="alert alert-danger" style={ { marginTop: '20px', marginBottom: '0' } }>
      <FormattedMessage id="create_account_error_email" />
    </div>
  );

  const initialFooter = (
    <Modal.Footer style={ { border: 0 } }>
      <Button className='softo-solid-btn' style={ {
        border: '2px solid #281483',
        background: 'transparent',
        color: '#281483',
        textTransform: 'none'
      } } onClick={ () => dismiss() }>
        <FormattedMessage id="create_account_cancel" />
      </Button>
      <Button className='softo-solid-btn'
              onClick={ () => handleSubmit() }>
        <FormattedMessage id="create_account_submit" />
      </Button>
    </Modal.Footer>
  );

  const closeFooter = (
    <Modal.Footer style={ { border: 0 } }>
      <Button className='softo-solid-btn' style={ {
        border: '2px solid #281483',
        background: 'transparent',
        color: '#281483',
        textTransform: 'none'
      } } onClick={ () => dismiss() }>
        <FormattedMessage id="create_account_close" />
      </Button>
    </Modal.Footer>
  );

  return (
    <div className="contact-us-form">
      <Modal.Header style={ { border: 0 } }>
        <Modal.Title>
          <FormattedMessage id="create_account_title" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group" style={ { marginBottom: '2rem' } }>
              <input type="text" className="form-control"
                     value={ formFields.firstName }
                     onChange={ createChangeHandler('firstName') }
                     placeholder={ intl.formatMessage({ id: 'create_account_first_name' }) } />
              {
                errorField('firstName')
              }
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group" style={ { marginBottom: '2rem' } }>
              <input type="text" className="form-control"
                     value={ formFields.lastName }
                     onChange={ createChangeHandler('lastName') }
                     placeholder={ intl.formatMessage({ id: 'create_account_last_name' }) } />
              {
                errorField('lastName')
              }
            </div>
          </div>
        </div>
        <div className="form-group" style={ { marginBottom: '2rem' } }>
          <input type="text"
                 className="form-control"
                 value={ formFields.name }
                 onChange={ createChangeHandler('name') }
                 placeholder={ intl.formatMessage({ id: 'create_account_name' }) } />
          {
            errorField('name')
          }
        </div>
        <div className="form-group" style={ { marginBottom: '2rem' } }>
          <input type="text"
                 className="form-control"
                 value={ formFields.phone }
                 onChange={ createChangeHandler('phone') }
                 placeholder={ intl.formatMessage({ id: 'create_account_phone' }) } />
          {
            errorField('name')
          }
        </div>

        <div className="form-group" style={ { marginBottom: '2rem' } }>
          <input type="text"
                 className="form-control"
                 value={ formFields.email }
                 onChange={ createChangeHandler('email') }
                 placeholder={ intl.formatMessage({ id: 'create_account_email' }) } />
          {
            errorField('email')
          }
          {
            state === CreateState.ERROR ? userExistsAlert : <span />
          }
        </div>

        <div className="form-group" style={ { marginBottom: '2rem' } }>
          <input type="password"
                 className="form-control"
                 value={ formFields.password }
                 onChange={ createChangeHandler('password') }
                 placeholder={ intl.formatMessage({ id: 'create_account_password' }) } />
          {
            errorField('password')
          }
        </div>
        <div className="form-group" style={ { marginBottom: '2rem' } }>
          <input type="password"
                 className="form-control"
                 value={ formFields.repassword }
                 onChange={ createChangeHandler('repassword') }
                 placeholder={ intl.formatMessage({ id: 'create_account_repassword' }) } />
          {
            errorField('repassword')
          }
        </div>

        <div className="form-group login-options">
          <label className="checkbox-inline" style={ {
            display: 'flex',
            alignItems: 'center'
          } }>
            <input type='checkbox'
                   style={ { marginTop: '0' } }
                   checked={ formFields.agreement }
                   onChange={ createChangeHandler('agreement') } />
            <div style={ {
              display: 'flex',
              flexWrap: 'wrap',
              marginLeft: '0.5rem'
            } }>
              <span style={{ marginRight: '5px' }}>
              <FormattedMessage id="create_account_agreement1" />
            </span>
              <a href='/regulations' target="_blank" className="text-purple-800">
                <FormattedMessage id="create_account_agreement2_link" />
              </a>
              <span style={{ margin: '0 5px' }}>
              <FormattedMessage id="create_account_agreement3" />
            </span>
              <a href='/privacy' target="_blank" className="text-purple-800">
                <FormattedMessage id="create_account_agreement4_link" />
              </a>
            </div>
          </label>
        </div>

        <div className="row">
          {
            state === CreateState.IN_PROGRESS ? bodyInProgress : <span />
          }
          {
            state === CreateState.SUCCESS ? bodySuccess : <span />
          }
        </div>
      </Modal.Body>

      {
        [
          CreateState.INITIAL,
          CreateState.ERROR
        ].indexOf(state) > -1 ? initialFooter : closeFooter
      }
    </div>
  );
};

